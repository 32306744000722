.logo {
  font-family: 'Your Font', sans-serif;
  /* Replace with your desired font */
  font-size: 48px;
  /* Adjust size as needed */
  font-weight: bold;
  /* Adjust weight as needed */
}

.logo-letter {
  font-family: 'Your Font', sans-serif;
  /* This should be the same as .logo for consistency */
}

.logo-tracker {
  color: #333;
  /* This color would be whatever you choose for 'tracker' */
}

.header-nav .active {
  font-weight: bold;
  color: #007bff;
  /* Example color for active links */
}