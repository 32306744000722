.membership-trends-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.membership-trends-container h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.community-info {
    margin-bottom: 20px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.community-info p {
    margin: 10px 0;
}

.trend-analysis {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #e9f7ef;
    border-left: 4px solid #28a745;
    border-radius: 8px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.membership-trends-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.membership-trends-container h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.community-info {
    margin-bottom: 20px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.community-info p {
    margin: 10px 0;
}

.community-info .community-link {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.community-info .community-link:hover {
    text-decoration: underline;
}

.trend-analysis {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #e9f7ef;
    border-left: 4px solid #28a745;
    border-radius: 8px;
}

.chart-container {
    margin-bottom: 20px;
}

.related-links {
    margin-top: 20px;
}

.related-links h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}