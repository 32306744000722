/* src/components/Footer.css */

.site-footer {
  background-color: #343a40;
  color: white;
  padding: 2rem 1rem;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* Center items for better appearance on all screen sizes */
  gap: 1rem;
  /* Add gaps for spacing */
}

.footer-column {
  flex: 1 1 200px;
  /* Allow shrink to the min-width of 200px */
  margin: 0.5rem;
  /* Add some space around each column */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* Center-align items */
}

.footer-column h3 {
  margin-bottom: 1rem;
}

.footer-column ul {
  list-style-type: none;
  padding-left: 0;
}

.footer-column ul li a {
  color: #007bff;
  text-decoration: none;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.feedback-button,
.subscribe-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.feedback-button:hover,
.subscribe-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-column {
    width: 100%;
    max-width: 300px;
  }
}

/* Additional styles for subscription prompt */
.subscription-prompt {
  color: #fff;
  /* Use white color for contrast */
  background-color: #007bff;
  /* Theme color for the prompt */
  border-radius: 4px;
  /* Rounded corners */
  padding: .5rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* Use full width */
}

.subscription-container {
  width: 100%;
  /* Full width for the container */
}

.email-input,
.subscribe-button {
  width: 100%;
  /* Full width for input and button */
  margin-top: 0.5rem;
  /* Add space between input/button and any other element below */
}

.subscribe-success {
  background-color: #28a745;
  /* Success color */
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-top: 1rem;
  display: inline-block;
  /* To keep the background around the text only */
}

/* Adjust the SVG icon color to white for better visibility */
.feather-mail {
  stroke: white;
}

/* Since the CSS uses a class-based approach, ensure your JSX components have the corresponding class names */