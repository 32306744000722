.top-bar {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.subscription-container {
  display: flex;
  flex-direction: column;
  width: calc(50% - 0.5rem);
  /* Adjust the width as needed */
}

.email-input,
.search-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 0;
  width: 100%;
}

.subscribe-button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
}

.subscribe-success {
  margin-top: 10px;
  /* Add margin for the success message */
  color: green;
  /* Color for success message */
}