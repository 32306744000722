/* Pagination.css */
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.page-button {
    padding: 10px 15px;
    margin: 0 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.page-button:hover {
    background-color: #0056b3;
}

.page-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.page-info {
    margin: 0 10px;
    font-size: 16px;
}