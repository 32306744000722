.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
body {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background-color: #f0f2f5;
}

.community-list {
  list-style-type: none;
  padding: 0;
}

.community-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.community-item h3 {
  margin-top: 0;
}

.community-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.community-cost {
  font-weight: bold;
  font-size: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.pagination button {
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  cursor: pointer;
  border-radius: 5px;
}

.pagination button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.sort-controls {
  margin-bottom: 20px;
}

.sort-controls select {
  padding: 10px;
  margin-left: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

