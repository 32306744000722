body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
    color: #333;
    margin: 0;
    padding: 20px;
}

.community-table-container {
    padding: 20px;
    overflow-x: auto;
}

.community-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.community-table th,
.community-table td {
    padding: 12px;
    border: 1px solid #ddd;
}

.community-table thead tr {
    background-color: #04AA6D;
    color: black;
}

.community-table th {
    background-color: #04AA6D;
    color: black;
    font-weight: bold;
    text-align: left;
    /* Keep left alignment for the headers */
}

.community-table th.sortable {
    cursor: pointer;
    text-align: center;
}

.community-table th.sorted-column {
    background-color: #e9e9e9;
}

.community-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.community-table tr:hover {
    background-color: #f1f1f1;
}

.community-table td.earnings {
    font-weight: bold;
    font-size: 1.1em;
}

.community-table a {
    color: #007bff;
    text-decoration: none;
}

.community-table a:hover {
    text-decoration: underline;
}

/* Center-align specific columns */
.center-align {
    text-align: center;
}

/* Specific columns to center-align */
.community-table td.center-align,
.community-table th.center-align {
    text-align: center;
}