/* TopEarningCommunities.css

.community-list {
    max-width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    padding: 1rem;
  }
  
  .community-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
  }
  
  .community-image {
    width: 100%;
    height: auto; 
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }
  
  .community-info {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .community-info h2 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
  
  .community-info p {
    margin: 0;
    padding: 0.25rem 0;
  }
  
  .learn-more-link {
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 1rem;
    align-self: center;
  }
  
  .top-earner {
    border: 2px solid gold; 
  }
  
  .top-earner-badge {
    display: inline-block;
    background: gold;
    color: #333;
    padding: 0.25rem 0.75rem;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .community-list {
      grid-template-columns: 1fr;
    }
  }
   */

   /* TopEarningCommunities.css */

   .community-list {
    max-width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
  }
    
  .community-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    position: relative; /* Set position context for absolute children */
  
  }
  
  .community-image {
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    aspect-ratio: 3 / 2; /* Set a fixed aspect ratio */
    object-fit: cover;
  }
  
  .community-info {
    padding: 1rem;
  }
  
  .community-info h2 {
    font-size: 1.5rem;
    color: #333;
    margin: 0.5rem 0;
  }
  
  .earnings, .members {
    font-size: 1rem;
    color: #666;
    margin: 0.25rem 0;
  }
  
  .description {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  
  .learn-more-link {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .learn-more-link:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .community-list {
      grid-template-columns: 1fr;
      padding: 1rem;
    }
  }
  
  /* Highlight top earners */
  .top-earner {
    border-color: gold;
    position: relative;
  }
  
  .top-earner-badge {
    position: absolute;
    top: 1%;
    left: 50%;
    transform: translate(-50%, -5%);
    background-color: gold;
    color: white;
    padding: 0.9rem 0.25rem;
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: bold;
    z-index: 10; /* Ensure it's above other content */
  }
  